<template>
    <div> 
    <div class="details-view mb-36">

        <InlineNotification Heading="GigPig House Rules" class="p-0 m-0">
            <template slot="body">
                <p class="text-justify">
                    Welcome to GigPig, the online booking platform where artists can connect with venues and event organisers to book gigs. 
                    To ensure a positive and harmonious experience for all users, we have established the following house rules and guidelines.
                </p>
            </template>
        </InlineNotification>
        <div class="max-w-4xl">
            <Accordian heading="1) Gig Etiquette" :toggle="false" bg_class="bg-gp_pink-default-500" class="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                <template #contents>
                    <InlineNotification class="p-0 m-0">
                        <template slot="body">
                            <p class="text-justify text-black">The easy stuff:</p>
                            <li>Be on time</li>
                            <li>Be professional</li>
                            <li>Be courteous</li>
                            <li>Don’t get drunk</li>
                        </template>
                    </InlineNotification>                
                </template>
            </Accordian>

            <Accordian heading="2) Follow the artist instructions" :toggle="false" bg_class="bg-gp_pink-default-500" class="mt-6 sm:mt-5 col-span-full space-y-6 sm:space-y-5">
                <template #contents>
                    <InlineNotification class="p-0 m-0">
                        <template slot="body">
                            <p class="text-justify text-black">Every gig has artist instructions attached to them. These include:</p>
                            <li>Set times</li>
                            <li>Dress code</li>
                            <li>Music brief</li>
                            <li>Venue instructions</li>
                            <li>Payment terms</li>
                            <p class="text-justify text-black">Please make sure you are familiar with these instructions.</p>
                        </template>
                    </InlineNotification>  
                </template>
            </Accordian>


            <Accordian heading="3) Gig cancellations" :toggle="false" bg_class="bg-gp_pink-default-500" class="mt-6 sm:mt-5 col-span-full space-y-6 sm:space-y-5">
                <template #contents>
                    <InlineNotification class="p-0 m-0">
                        <template slot="body">
                            <p class="text-justify text-black">
                                Last minute cancellations and no-shows reflect badly on you as an artist to venues, which may result in you not being considered for future work by them. 
                                Please give as much notice as possible in order to give the best chance to cover you.
                            </p>
                        </template>
                    </InlineNotification>
                    
                </template>
            </Accordian>

            <Accordian heading="4) Respect and Professionalism" :toggle="false" bg_class="bg-gp_pink-default-500" class="mt-6 sm:mt-5 col-span-full space-y-6 sm:space-y-5">
                <template #contents>
                    <InlineNotification class="p-0 m-0">
                        <template slot="body">
                            <p class="text-justify text-black">
                                Treat everyone associated with your gig with respect and professionalism. Discrimination, harassment, or any form of abusive behaviour will not be tolerated.
                            </p>
                        </template>
                    </InlineNotification>
                </template>
            </Accordian>

            <Accordian heading="5) Dispute Resolution" :toggle="false" bg_class="bg-gp_pink-default-500" class="mt-6 sm:mt-5 col-span-full space-y-6 sm:space-y-5">
                <template #contents>
                    <InlineNotification class="p-0 m-0">
                        <template slot="body">
                            <p class="text-justify text-black">
                                In the event of a dispute, contact GigPig for assistance. Provide all relevant information and evidence to help us resolve the issue fairly.
                            </p>
                        </template>
                    </InlineNotification>
                </template>
            </Accordian>

            <Accordian heading="6) Termination of Accounts" :toggle="false" bg_class="bg-gp_pink-default-500" class="mt-6 sm:mt-5  col-span-full space-y-6 sm:space-y-5">
                <template #contents>
                    <InlineNotification class="p-0 m-0">
                        <template slot="body">
                            <p class="text-justify text-black">
                                Violation of these house rules may result in the suspension or termination of your account on the platform.
                            </p>
                            <p class="text-justify text-black">
                                When accepting gigs, you agree to abide by these house rules and contribute to a positive and supportive community for artists, venues, 
                                and event organisers. We are committed to facilitating successful and enjoyable music bookings for everyone. 
                            </p>
                        </template>
                    </InlineNotification>
                </template>
            </Accordian>
        </div>
    </div>
</div>

</template>

<script>
import InlineNotification from '../../../components/notifications/inline.vue'
import Accordian from '../../../components/accordian/Accordian.vue' 

export default
{
    components: {
        InlineNotification,
        Accordian
    }
}
</script>